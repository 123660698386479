import {
  Component,
  effect,
  EventEmitter,
  Inject,
  inject,
  OnDestroy,
  Output,
  Signal,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RiskWizard } from '../../data-access/models/risk-wizard';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  impactAreaRiskDetails,
  RiskRegisterDetails,
} from '../../data-access/models/hazard-types.model';
import { KeyValuePipe, NgClass, NgForOf } from '@angular/common';
import { CreateEvent } from '../create-event';
import { RiskInfoService } from '../../../bottom-drawer/data-acces/risk-info.service';
import { MultiSelectChangeEvent, MultiSelectModule } from 'primeng/multiselect';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';

@Component({
  selector: 'app-register-risk',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    KeyValuePipe,
    NgForOf,
    NgClass,
    FloatLabelModule,
    InputTextareaModule,
    DropdownModule,
    MultiSelectModule,
    AccordionModule,
  ],
  templateUrl: './register-risk.component.html',
  styleUrl: './register-risk.component.css',
})
export class RegisterRiskComponent implements OnDestroy {
  form: FormGroup;
  protected readonly RiskWizard = RiskWizard;
  protected multipleImpactedAreas: string[];

  readonly riskInfoService: RiskInfoService = inject(RiskInfoService);
  readonly formBuilder: FormBuilder = inject(FormBuilder);
  private readonly dialogRef: DialogRef<RegisterRiskComponent> = inject(
    DialogRef<RegisterRiskComponent>
  );

  @Output() createEvent: EventEmitter<any> = new EventEmitter<any>();
  maxConsequence: string;

  constructor(@Inject(DIALOG_DATA) protected data: { count: number }) {
    this.multipleImpactedAreas = [];
    this.form = this.formBuilder.group({
      hazardStatement: ['', [Validators.required]],
      consequenceStatement: ['', [Validators.required]],
      likelihood: ['', [Validators.required]],
      impactArea: ['', [Validators.required]],
      impactedAreas: this.formBuilder.array([]),
    });

    this.riskInfoService.likelihood = this.form.get('likelihood').valueChanges;

    const multiImpArea: Signal<any[]> = toSignal(
      this.form.get('impactedAreas').valueChanges
    );

    effect(
      () => {
        if (
          multiImpArea() &&
          !multiImpArea().some((i) =>
            Object.values(i).some((value) => value === null)
          )
        ) {
          this.maxConsequence = this.riskInfoService.getMaxConsequence(
            multiImpArea()
          );
        }
      },
      { allowSignalWrites: true }
    );
  }

  get impactedAreas(): FormArray {
    return this.form.get('impactedAreas') as FormArray;
  }

  cancel(): void {
    this.createEvent.emit(CreateEvent.cancel<RiskRegisterDetails>());
    this.dialogRef.close();
  }

  submit(): void {
    let details: RiskRegisterDetails = {
      hazard: this.form.get('hazardStatement').value,
      consequence: this.form.get('consequenceStatement').value,
      eventLikelihood: this.form.get('likelihood').value,
      impactAreas: this.form.get('impactArea').value,
      impactAreaRiskDetails: this._getImpactedAreaConsequenceArray(),
      maximumConsequence: this.maxConsequence,
    };

    this.createEvent.emit(CreateEvent.create<RiskRegisterDetails>(details));
    this.dialogRef.close();
  }

  impactAreaChange($event: MultiSelectChangeEvent) {
    const isAddition = !this.multipleImpactedAreas.includes($event.itemValue);
    if (isAddition) {
      this.impactedAreas.push(
        this.riskInfoService.setFormGroupControlValueChanges(
          this.formBuilder,
          $event.itemValue
        )
      );
    } else {
      this.riskInfoService.removeImpactedArea(
        $event.itemValue,
        this.impactedAreas
      );
      const index = this.multipleImpactedAreas.indexOf($event.itemValue);
      this.multipleImpactedAreas.splice(index, 1);
    }
    this.multipleImpactedAreas = $event.value;
  }

  _getImpactedAreaConsequenceArray(): impactAreaRiskDetails[] {
    return this.impactedAreas.controls.reduce(
      (acc: impactAreaRiskDetails[], formGroup: FormGroup) => {
        let key: string = Object.keys(formGroup.controls)[0];
        const fGroup: FormGroup = formGroup.controls[key] as FormGroup;
        let impAreaCos: impactAreaRiskDetails = {
          [key]: {
            consequence: this.riskInfoService.getConsequenceName(
              fGroup.controls['consequence'].value
            ),
            controlStrength: fGroup.controls['ctrlStrength'].value,
            controlExpediency: fGroup.controls['ctrlExpediency'].value,
            confidenceLevel: fGroup.controls['confidenceLvl'].value,
            natureOfImpactTo: fGroup.controls['impactNature'].value,
            existingControls: fGroup.controls['existingControls'].value,
            controlEffectiveness:
              this.riskInfoService.likelihoodState.get(key).ctrlEffectiveness$
                .value.value,
            likelihoodAfterControl:
              this.riskInfoService.likelihoodState.get(key).likelihoodAfterCtrl$
                .value.value,
            risk: this.riskInfoService.likelihoodState.get(key).riskLevel$.value
              .value,
            priority: this.riskInfoService.likelihoodState
              .get(key)
              .priorityLevel$.value.rating.toString(),
          },
        };
        acc.push(impAreaCos);
        return acc;
      },
      []
    );
  }

  ngOnDestroy(): void {
    this.riskInfoService.likelihoodState.forEach((value) => {
      value.ctrlEffectiveness$.unsubscribe();
      value.likelihoodAfterCtrl$.unsubscribe();
      value.riskLevel$.unsubscribe();
      value.priorityLevel$.unsubscribe();
      value.likelihood$.unsubscribe();
    });

    this.riskInfoService.likelihoodState.clear();
  }
}
