<div class="layer">
  <div class="flex items-center content-center gap--xs">
    <button LayerVisibilityControl class="feature-layer-accordion-item-button"
            [layer]="layer().layer" [disabled]="!layer().canActivate" [currentVisibleState]="layer().visible">
      @switch (layer().visible) {
        @case ('visible') {
          <i class="fa fa-eye"></i>
        }
        @case ('partial') {
          <i class="fa fa-low-vision"></i>
        }
        @case ('hidden') {
          <i class="fa fa-eye-slash"></i>
        }
        @default {
          <i class="fa fa-eye"></i>
        }
      }
    </button>

    <style-renderer #renderer [kind]="layer().kind" [style]="layer().layer.getStyle()"
      [featureProperties]="layer().featureProperties"></style-renderer>
    <span>{{ layer().title }} </span>
  </div>

  <label>Opacity:
    <input autocomplete="off" data-lpignore="true" #opacityInput type="range" min="0" max="1"
           step="0.01" layerOpacityControl [layer]="layer().layer" [value]="layer().opacity"/>
  </label>
  <button (click)="removeLayer()">
    <span><i class="fa fa-trash"></i></span>
  </button>
</div>
