<cdk-accordion-item #accordionItem1="cdkAccordionItem" class="dc-accordion-item" role="button"
  [ngClass]="{ collapsed: !accordionItem1.expanded, expanded: accordionItem1.expanded }">
  <div class="dc-accordion-item-header" (click)="accordionItem1.toggle()"
       [ngClass]="{ 'sticky-header': accordionItem1.expanded }">
    <span>
      <button (click)="changeVisibility(layerGroup().layer, layerGroup().visible, $event)"
        class="feature-layer-accordion-item-button" [disabled]="!layerGroup().canActivate">
        @switch (layerGroup().visible) {
          @case ('visible') {
          <i class="fa fa-eye"></i>
          }
          @case ('partial') {
            <i class="fa fa-low-vision"></i>
          }
          @case ('hidden') {
            <i class="fa fa-eye-slash"></i>
          }
          @default {
            <i class="fa fa-eye"></i>
          }
        }
      </button>
      {{ layerGroup().title }}
    </span>

    <div class="flex gap--sm align-center">
      @if(layerGroup().hasRemoveFn) {
      <button class="button button--icon" (click)="layerGroup().removeFn($event)">
        <span><i class="fa fa-trash"></i></span>
      </button>
      }
      <span>
        @if (accordionItem1.expanded) {
          <i class="fa-solid fa-chevron-up"></i>
        }
        @else {
          <i class="fa-solid fa-chevron-down"></i>
        }
      </span>
    </div>
  </div>
  <div class="dc-accordion-item-body" role="region"
    [@slideInOut]="accordionItem1.expanded ? 'in' : 'out'">
    <div cdkDropList
      [cdkDropListData]="layerGroup().layer" (cdkDropListDropped)="drop($event)"
      class="layers">
      @for (layer of layerGroup().children.slice().reverse(); track layer().title) {
        @switch (layer().type) {
          @case ('group') {
            <cdk-accordion #cdkAccordion="cdkAccordion" cdkDrag class="dc-accordion">
              <app-layer-group [hasParent]="true" [parentAccordion]="cdkAccordion"
                               [parentExpanded]="accordionItem1.expanded" [layerGroup]="layer()"
                               [map]="map()"></app-layer-group>
            </cdk-accordion>
          }
          @case ('vector') {
            <app-vector-layer cdkDrag [cdkDragData]="layer().layer" [layerGroup]="layerGroup()"
                            [layer]="layer()"></app-vector-layer>
          }
          @case ('image') {
            <app-image-layer cdkDrag [cdkDragData]="layer().layer" [layerGroup]="layerGroup()"
                             [layer]="layer()"></app-image-layer>
          }
          @default { }
        }
      }
    </div>
  </div>
</cdk-accordion-item>
