import {
  Component,
  effect,
  inject,
  Inject,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import { MultiSelectChangeEvent, MultiSelectModule } from 'primeng/multiselect';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { RiskDetail } from '../../../risk-wizard-page/data-access/models/risk-detail.model';
import { NgClass } from '@angular/common';
import { RiskWizard } from '../../../risk-wizard-page/data-access/models/risk-wizard';
import { toSignal } from '@angular/core/rxjs-interop';
import { RiskInfoService } from '../../data-acces/risk-info.service';
import { ModalService } from '../../../../../../shared/services/modal/modal.service';
import { FloatLabelModule } from 'primeng/floatlabel';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-view-edit-risk-detail',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    FormsModule,
    FloatLabelModule,
    AccordionModule,
    DropdownModule,
    InputTextareaModule,
    MultiSelectModule,
    InputTextModule,
  ],
  templateUrl: './view-edit-risk-detail.component.html',
  styleUrl: './view-edit-risk-detail.component.css',
})
export class ViewEditRiskDetailComponent {
  form: FormGroup;
  mode: WritableSignal<'view' | 'edit'>;
  protected readonly RiskWizard = RiskWizard;
  protected multipleImpactedAreas: string[];
  riskInfo: RiskDetail;
  readonly riskInfoService: RiskInfoService = inject(RiskInfoService);
  readonly modalService: ModalService = inject(ModalService);
  readonly formBuilder: FormBuilder = inject(FormBuilder);
  private readonly dialogRef: DialogRef<ViewEditRiskDetailComponent> = inject(
    DialogRef<ViewEditRiskDetailComponent>
  );
  maxConsequence: number;
  constructor(@Inject(DIALOG_DATA) protected data: { risk: RiskDetail }) {
    this.multipleImpactedAreas = [];
    this.mode = signal('view');
    this.riskInfo = data.risk;

    effect(() => {
      if (this.mode() === 'edit') {
        this.form.controls['hazardStatement'].enable();
        this.form.controls['consequenceStatement'].enable();
        this.form.controls['likelihood'].enable();
        this.form.controls['impactArea'].enable();
        (this.form.controls['impactedAreas'] as FormArray).controls.forEach(
          (group: FormGroup) => {
            let ctrl = Object.values(group.controls)[0] as FormGroup;
            ctrl.controls['consequence'].enable();
            ctrl.controls['ctrlStrength'].enable();
            ctrl.controls['ctrlExpediency'].enable();
            ctrl.controls['confidenceLvl'].enable();
            ctrl.controls['impactNature'].enable();
            ctrl.controls['existingControls'].enable();
          }
        );
      }
    });

    this.form = this.formBuilder.group({
      hazardStatement: [
        { value: data.risk.hazardStatement, disabled: true },
        [Validators.required],
      ],
      consequenceStatement: [
        { value: data.risk.consequenceStatement, disabled: true },
        [Validators.required],
      ],
      likelihood: [{ value: '', disabled: true }, [Validators.required]],
      impactArea: [
        { value: data.risk.impactArea, disabled: true },
        [Validators.required],
      ],
      impactedAreas: this.formBuilder.array([]),
    });

    this.riskInfoService.likelihood = this.form.get('likelihood').valueChanges;
    this.maxConsequence = this.riskInfoService.getConsequenceValue(
      data.risk.maxConsequence
    );
    this.form.get('likelihood').setValue(data.risk.likelihood);

    data.risk.impactedAreas.forEach((impactArea) => {
      this.multipleImpactedAreas.push(impactArea.impactArea);
      const group = this.riskInfoService.setFormGroupControlValueChanges(
        this.formBuilder,
        impactArea.impactArea,
        true
      );
      this.impactedAreas.push(group);
      const formGrp = group.controls[impactArea.impactArea] as FormGroup;
      formGrp.controls['consequence'].setValue(
        this.riskInfoService.getConsequenceValue(impactArea.consequence)
      );
      formGrp.controls['ctrlStrength'].setValue(impactArea.controlStrength);
      formGrp.controls['ctrlExpediency'].setValue(impactArea.controlExpediency);
      formGrp.controls['confidenceLvl'].setValue(impactArea.confidenceLevel);
      formGrp.controls['impactNature'].setValue(impactArea.impactArea);
      formGrp.controls['existingControls'].setValue(
        impactArea.existingControls
      );
    });

    const multiImpArea: Signal<any[]> = toSignal(
      this.form.get('impactedAreas').valueChanges
    );

    effect(
      () => {
        if (
          multiImpArea() &&
          !multiImpArea().some((i) =>
            Object.values(i).some((value) => value === null)
          )
        ) {
          this.maxConsequence = this.riskInfoService.getMaxConsequence(
            multiImpArea()
          );
        }
      },
      { allowSignalWrites: true }
    );
  }

  get impactedAreas(): FormArray {
    return this.form.get('impactedAreas') as FormArray;
  }

  cancel(): void {
    //TODO - this.createEvent.emit(CreateEvent.cancel<RiskRegisterDetails>());
    this.dialogRef.close();
  }

  submit() {}

  impactAreaChange($event: MultiSelectChangeEvent) {
    const existing = this.riskInfo.impactArea.find(
      (item) => item === $event.itemValue
    );
    const index: number = this.multipleImpactedAreas.indexOf($event.itemValue);
    const existingIndex: number = this.riskInfo.impactArea.indexOf(
      $event.itemValue
    );

    if (existing && existing !== '') {
      this.modalService
        .showConfirmation(
          `Are you sure.<br>This will clear out existing data for Impact Area: ${existing}`
        )
        .subscribe((next) => {
          if (!next) {
            this.form.get('impactArea').setValue(this.multipleImpactedAreas);
          } else {
            this.riskInfo.impactArea.splice(existingIndex, 1);
            this.riskInfoService.removeImpactedArea(
              $event.itemValue,
              this.impactedAreas
            );
            this.multipleImpactedAreas.splice(index, 1);
          }
        });
    } else {
      const isAddition = !this.multipleImpactedAreas.includes($event.itemValue);
      if (isAddition) {
        this.impactedAreas.push(
          this.riskInfoService.setFormGroupControlValueChanges(
            this.formBuilder,
            $event.itemValue
          )
        );
        this.multipleImpactedAreas.push($event.value);
      } else {
        this.riskInfoService.removeImpactedArea(
          $event.itemValue,
          this.impactedAreas
        );
        this.multipleImpactedAreas.splice(index, 1);
      }
    }
  }

  deleteRisk() {
    this.modalService.showConfirmDelete(
      'Risk Information',
      this.riskInfo.riskRef
    );
    //TODO
  }
}
