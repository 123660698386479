import {
  Consequence,
  ControlEffectivenessMatrix,
  LikelihoodAfterControlMatrix,
  NameState,
  NameType,
  PriorityLevel,
  RiskControlMatrix,
  RiskMatrix,
  WizardFeature,
} from './hazard-types.model';
import { RiskDetail } from './risk-detail.model';

export class RiskWizard {
  static readonly ALL: NameState[] = [
    { name: 'Biosecurity', disabled: true },
    { name: 'Coastal Erosion', disabled: true },
    { name: 'Coastal Inundation - storm tide', disabled: true },
    { name: 'Earthquake', disabled: true },
    { name: 'Energy Supply Emergency', disabled: true },
    { name: 'Fire-bush', disabled: true },
    { name: 'Fire - structure', disabled: true },
    { name: 'Flood - dam emergency', disabled: true },
    { name: 'Flood - flash', disabled: true },
    { name: 'Flood - river', disabled: false },
    { name: 'Food Contamination', disabled: true },
    { name: 'Hazardous Material', disabled: true },
    { name: 'Hazardous Material - radiological accident ', disabled: true },
    { name: 'Heatwave', disabled: true },
    { name: 'Influenza Pandemic', disabled: true },
    { name: 'Infrastructure Failure - building collapse', disabled: true },
    {
      name: 'Infrastructure Failure - roads and bridges collapse',
      disabled: true,
    },
    { name: 'Intentional Violence', disabled: true },
    { name: 'Landslip, Landslide', disabled: true },
    { name: 'Marine Pollution', disabled: true },
    { name: 'Nuclear Powered Warships Visits', disabled: true },
    { name: 'Public Health Emergency', disabled: true },
    { name: 'Space Debris', disabled: true },
    { name: 'Storm, High Wind, Tempest', disabled: true },
    { name: 'Transport Crash - aviation', disabled: true },
    { name: 'Transport Crash - marine', disabled: true },
    { name: 'Transport Crash - railway', disabled: true },
    { name: 'Transport Crash - road vehicle', disabled: true },
    { name: 'Tsunami', disabled: true },
    { name: 'Water Supply Contamination', disabled: true },
  ];

  static readonly ANALYSIS_ZONES: NameType[] = [
    { name: 'Local Government Areas', type: 'Local Government Areas' },
    { name: 'Human Settlement Areas', type: 'Human Settlement Areas' },
  ];
  static readonly INFRASTRUCTURE: string[] = ['Transport'];

  //TODO to the correct one
  static readonly FEATURE: WizardFeature[] = [
    { name: 'Structures', disabled: false, layer: '10' },
    { name: 'Roads', disabled: true, layer: '' },
  ];

  static readonly SELECTION_TYPE: NameType[] = [
    { name: 'Existing Risk CSV', type: 'Upload' },
    { name: 'Point - Feature', type: 'Point' },
    { name: 'Polygon - Area', type: 'Polygon' },
  ];

  static readonly IMPACT_AREA: string[] = [
    'Structure',
    'Economy',
    'Environment',
    'People',
    'Public Administration',
    'Social Setting',
  ];

  static readonly CONSEQUENCE: Consequence[] = [
    { name: 'Insignificant', value: 1 },
    { name: 'Minor', value: 2 },
    { name: 'Moderate', value: 3 },
    { name: 'Major', value: 4 },
    { name: 'Catastrophic', value: 5 },
  ];

  static readonly CONFIDENCE: string[] = [
    'Highest',
    'High',
    'Moderate',
    'Low',
    'Lowest',
  ];

  static readonly TREATMENT: string[] = [
    'Treatment',
    'Further analysis',
    'Monitor and review',
  ];

  static readonly TREATMENT_STRATEGY: string[] = [
    'Change the likelihood and consequence of the risk',
    'Avoiding the risk',
    'Removing the risk source',
    'Change the consequence of the risk',
    'Change the likelihood of the risk',
    'Retain the risk by informed decision',
    'Sharing the risk',
  ];

  static readonly PREV_PREP_RESP: string[] = [
    'Council',
    'DPIPWE',
    'TFS',
    'PWS',
    'STT',
    'SES',
    'State Growth',
    'MRT',
    'Biosecurity Tas',
    'Infrastructure Tas',
    'DHHS',
    'Public Health Services',
    'Traffic Infrastructure Services',
    'EPA',
    'Commonwealth',
    'MAST',
    'TasRail',
    'DPFEM',
    'DoJ (Land-use Planning)',
    'DPAC',
    'TASPOL',
    'Hydro Tas',
    'TasWater',
    'TasIrrigation',
    'Airservices Australia',
  ];

  static readonly LIKELIHOOD_LEVEL: string[] = [
    'Almost Certain',
    'Likely',
    'Unlikely',
    'Rare',
    'Very Rare',
    'Extremely Rare',
  ];

  static readonly CONTROL_STRENGTH_EXPEDIENCY: string[] = [
    'Very low',
    'Low',
    'Medium',
    'High',
  ];

  static readonly CTRL_EFFECTIVE_MATRIX: ControlEffectivenessMatrix = {
    High: {
      'VERY LOW': { value: 'LOW', colour: '#f57c00' }, // Orange
      LOW: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MEDIUM: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      HIGH: { value: 'HIGH', colour: '#8bc34a' }, // Green
    },
    Medium: {
      'VERY LOW': { value: 'LOW', colour: '#f57c00' }, // Orange
      LOW: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MEDIUM: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      HIGH: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
    },
    Low: {
      'VERY LOW': { value: 'VERY LOW', colour: '#d32f2f' }, // Red
      LOW: { value: 'LOW', colour: '#f57c00' }, // Orange
      MEDIUM: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      HIGH: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
    },
    'Very low': {
      'VERY LOW': { value: 'VERY LOW', colour: '#d32f2f' }, // Red
      LOW: { value: 'VERY LOW', colour: '#d32f2f' }, // Red
      MEDIUM: { value: 'LOW', colour: '#f57c00' }, // Orange
      HIGH: { value: 'LOW', colour: '#f57c00' }, // Orange
    },
  };

  static readonly LIKELIHOOD_AFTER_CTRL_MATRIX: LikelihoodAfterControlMatrix = {
    'Almost Certain': {
      'VERY LOW': { value: 'ALMOST CERTAIN', colour: '#d32f2f' }, // Red
      LOW: { value: 'ALMOST CERTAIN', colour: '#d32f2f' }, // Red
      MEDIUM: { value: 'LIKELY', colour: '#f57c00' }, // Orange
      HIGH: { value: 'LIKELY', colour: '#f57c00' }, // Orange
    },
    Likely: {
      'VERY LOW': { value: 'LIKELY', colour: '#f57c00' }, // Orange
      LOW: { value: 'LIKELY', colour: '#f57c00' }, // Orange
      MEDIUM: { value: 'UNLIKELY', colour: '#fbc02d' }, // Yellow
      HIGH: { value: 'UNLIKELY', colour: '#fbc02d' }, // Yellow
    },
    Unlikely: {
      'VERY LOW': { value: 'UNLIKELY', colour: '#fbc02d' }, // Yellow
      LOW: { value: 'UNLIKELY', colour: '#fbc02d' }, // Yellow
      MEDIUM: { value: 'UNLIKELY', colour: '#fbc02d' }, // Yellow
      HIGH: { value: 'RARE', colour: '#8bc34a' }, // Green
    },
    Rare: {
      'VERY LOW': { value: 'RARE', colour: '#8bc34a' }, // Green
      LOW: { value: 'RARE', colour: '#8bc34a' }, // Green
      MEDIUM: { value: 'RARE', colour: '#8bc34a' }, // Green
      HIGH: { value: 'VERY RARE', colour: '#29b6f6' }, // Light Blue
    },
    'Very Rare': {
      'VERY LOW': { value: 'VERY RARE', colour: '#29b6f6' }, // Light Blue
      LOW: { value: 'VERY RARE', colour: '#29b6f6' }, // Light Blue
      MEDIUM: { value: 'VERY RARE', colour: '#29b6f6' }, // Light Blue
      HIGH: { value: 'VERY RARE', colour: '#29b6f6' }, // Light Blue
    },
    'Extremely Rare': {
      'VERY LOW': { value: 'EXTREMELY RARE', colour: '#0277bd' }, // Blue
      LOW: { value: 'EXTREMELY RARE', colour: '#0277bd' }, // Blue
      MEDIUM: { value: 'EXTREMELY RARE', colour: '#0277bd' }, // Blue
      HIGH: { value: 'EXTREMELY RARE', colour: '#0277bd' }, // Blue
    },
  };

  static readonly RISK_MATRIX: RiskMatrix = {
    'Almost Certain': {
      INSIGNIFICANT: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MINOR: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MODERATE: { value: 'HIGH', colour: '#f57c00' }, // Orange
      MAJOR: { value: 'EXTREME', colour: '#d32f2f' }, // Red
      CATASTROPHIC: { value: 'EXTREME', colour: '#d32f2f' }, // Red
    },
    Likely: {
      INSIGNIFICANT: { value: 'LOW', colour: '#8bc34a' }, // Green
      MINOR: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MODERATE: { value: 'HIGH', colour: '#f57c00' }, // Orange
      MAJOR: { value: 'EXTREME', colour: '#d32f2f' }, // Red
      CATASTROPHIC: { value: 'EXTREME', colour: '#d32f2f' }, // Red
    },
    Unlikely: {
      INSIGNIFICANT: { value: 'LOW', colour: '#8bc34a' }, // Green
      MINOR: { value: 'LOW', colour: '#8bc34a' }, // Green
      MODERATE: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MAJOR: { value: 'HIGH', colour: '#f57c00' }, // Orange
      CATASTROPHIC: { value: 'EXTREME', colour: '#d32f2f' }, // Red
    },
    Rare: {
      INSIGNIFICANT: { value: 'VERY LOW', colour: '#29b6f6' }, // Light Blue
      MINOR: { value: 'LOW', colour: '#8bc34a' }, // Green
      MODERATE: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MAJOR: { value: 'HIGH', colour: '#f57c00' }, // Orange
      CATASTROPHIC: { value: 'HIGH', colour: '#f57c00' }, // Orange
    },
    'Very Rare': {
      INSIGNIFICANT: { value: 'VERY LOW', colour: '#29b6f6' }, // Light Blue
      MINOR: { value: 'VERY LOW', colour: '#29b6f6' }, // Light Blue
      MODERATE: { value: 'LOW', colour: '#8bc34a' }, // Green
      MAJOR: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      CATASTROPHIC: { value: 'HIGH', colour: '#f57c00' }, // Orange
    },
    'Extremely rare': {
      INSIGNIFICANT: { value: 'VERY LOW', colour: '#29b6f6' }, // Light Blue
      MINOR: { value: 'VERY LOW', colour: '#29b6f6' }, // Light Blue
      MODERATE: { value: 'LOW', colour: '#8bc34a' }, // Green
      MAJOR: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      CATASTROPHIC: { value: 'HIGH', colour: '#f57c00' }, // Orange
    },
  };

  static readonly RISK_CTRL_MATRIX: RiskControlMatrix = {
    High: {
      controlStrength:
        'Control is highly effective in reducing the level of risk',
      controlExpediency: ['The control is frequently applied.'],
    },
    Medium: {
      controlStrength: 'Control is effective in reducing the level of risk',
      controlExpediency: [
        'The control is infrequently applied and is outside of the operators’ everyday experience.',
        'The use of the control has been foreseen and plans for its application have been prepared and tested.',
        'Some extraordinary cost may be required to apply the control.',
      ],
    },
    Low: {
      controlStrength: 'Control has some effect in reducing the level of risk',
      controlExpediency: [
        'The control is applied rarely and operators may not have experienced using it.',
        'The use of the control may have been foreseen and plans for its application may have been considered, but it is not part of normal operational protocols and has been tested.',
        'Extraordinary cost is required to apply the control, which may be difficult to obtain.',
      ],
    },
    'Very low': {
      controlStrength:
        'Control has almost no effect in reducing the level of risk',
      controlExpediency: [
        'Application of the control is outside of the experience and planning of operators, with no effective procedures or plans for its operation.',
        'It has not been foreseen that the control will ever need to be used.',
        'The application of the control requires significant cost over and above existing resources, and the cost will most likely be objected to by a number of stakeholders.',
      ],
    },
  };

  static PRIORITY_LEVELS: PriorityLevel = {
    Highest: {
      Insignificant: {
        'Almost Certain': { rating: 4, colour: '#eae7f0' },
        Likely: { rating: 5, colour: '#dbeab5' },
        Unlikely: { rating: 5, colour: '#dbeab5' },
        Rare: { rating: 5, colour: '#dbeab5' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Minor: {
        'Almost Certain': { rating: 4, colour: '#eae7f0' },
        Likely: { rating: 4, colour: '#eae7f0' },
        Unlikely: { rating: 5, colour: '#dbeab5' },
        Rare: { rating: 5, colour: '#dbeab5' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Moderate: {
        'Almost Certain': { rating: 3, colour: '#cfbedd' },
        Likely: { rating: 4, colour: '#eae7f0' },
        Unlikely: { rating: 4, colour: '#eae7f0' },
        Rare: { rating: 5, colour: '#dbeab5' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Major: {
        'Almost Certain': { rating: 2, colour: '#a68bbf' },
        Likely: { rating: 2, colour: '#a68bbf' },
        Unlikely: { rating: 3, colour: '#cfbedd' },
        Rare: { rating: 3, colour: '#cfbedd' },
        'Very Rare': { rating: 4, colour: '#eae7f0' },
        'Extremely Rare': { rating: 4, colour: '#eae7f0' },
      },
      Catastrophic: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 2, colour: '#a68bbf' },
        Unlikely: { rating: 2, colour: '#a68bbf' },
        Rare: { rating: 3, colour: '#cfbedd' },
        'Very Rare': { rating: 3, colour: '#cfbedd' },
        'Extremely Rare': { rating: 4, colour: '#eae7f0' },
      },
    },
    High: {
      Insignificant: {
        'Almost Certain': { rating: 4, colour: '#eae7f0' },
        Likely: { rating: 4, colour: '#eae7f0' },
        Unlikely: { rating: 5, colour: '#dbeab5' },
        Rare: { rating: 5, colour: '#dbeab5' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Minor: {
        'Almost Certain': { rating: 3, colour: '#cfbedd' },
        Likely: { rating: 4, colour: '#eae7f0' },
        Unlikely: { rating: 4, colour: '#eae7f0' },
        Rare: { rating: 5, colour: '#dbeab5' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Moderate: {
        'Almost Certain': { rating: 2, colour: '#a68bbf' },
        Likely: { rating: 3, colour: '#cfbedd' },
        Unlikely: { rating: 3, colour: '#cfbedd' },
        Rare: { rating: 4, colour: '#eae7f0' },
        'Very Rare': { rating: 4, colour: '#eae7f0' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Major: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 2, colour: '#a68bbf' },
        Unlikely: { rating: 2, colour: '#a68bbf' },
        Rare: { rating: 3, colour: '#cfbedd' },
        'Very Rare': { rating: 3, colour: '#cfbedd' },
        'Extremely Rare': { rating: 4, colour: '#eae7f0' },
      },
      Catastrophic: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 2, colour: '#a68bbf' },
        Rare: { rating: 2, colour: '#a68bbf' },
        'Very Rare': { rating: 3, colour: '#cfbedd' },
        'Extremely Rare': { rating: 3, colour: '#cfbedd' },
      },
    },
    Moderate: {
      Insignificant: {
        'Almost Certain': { rating: 3, colour: '#cfbedd' },
        Likely: { rating: 4, colour: '#eae7f0' },
        Unlikely: { rating: 4, colour: '#eae7f0' },
        Rare: { rating: 5, colour: '#dbeab5' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Minor: {
        'Almost Certain': { rating: 3, colour: '#cfbedd' },
        Likely: { rating: 3, colour: '#cfbedd' },
        Unlikely: { rating: 4, colour: '#eae7f0' },
        Rare: { rating: 4, colour: '#eae7f0' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Moderate: {
        'Almost Certain': { rating: 2, colour: '#a68bbf' },
        Likely: { rating: 2, colour: '#a68bbf' },
        Unlikely: { rating: 3, colour: '#cfbedd' },
        Rare: { rating: 3, colour: '#cfbedd' },
        'Very Rare': { rating: 4, colour: '#eae7f0' },
        'Extremely Rare': { rating: 4, colour: '#eae7f0' },
      },
      Major: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 2, colour: '#a68bbf' },
        Rare: { rating: 2, colour: '#a68bbf' },
        'Very Rare': { rating: 3, colour: '#cfbedd' },
        'Extremely Rare': { rating: 3, colour: '#cfbedd' },
      },
      Catastrophic: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 1, colour: '#8267a5' },
        Rare: { rating: 2, colour: '#a68bbf' },
        'Very Rare': { rating: 2, colour: '#a68bbf' },
        'Extremely Rare': { rating: 3, colour: '#cfbedd' },
      },
    },
    Low: {
      Insignificant: {
        'Almost Certain': { rating: 3, colour: '#cfbedd' },
        Likely: { rating: 3, colour: '#cfbedd' },
        Unlikely: { rating: 4, colour: '#eae7f0' },
        Rare: { rating: 4, colour: '#eae7f0' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Minor: {
        'Almost Certain': { rating: 2, colour: '#a68bbf' },
        Likely: { rating: 3, colour: '#cfbedd' },
        Unlikely: { rating: 3, colour: '#cfbedd' },
        Rare: { rating: 4, colour: '#eae7f0' },
        'Very Rare': { rating: 4, colour: '#eae7f0' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Moderate: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 2, colour: '#a68bbf' },
        Unlikely: { rating: 2, colour: '#a68bbf' },
        Rare: { rating: 3, colour: '#cfbedd' },
        'Very Rare': { rating: 3, colour: '#cfbedd' },
        'Extremely Rare': { rating: 4, colour: '#eae7f0' },
      },
      Major: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 1, colour: '#8267a5' },
        Rare: { rating: 2, colour: '#a68bbf' },
        'Very Rare': { rating: 2, colour: '#a68bbf' },
        'Extremely Rare': { rating: 3, colour: '#cfbedd' },
      },
      Catastrophic: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 1, colour: '#8267a5' },
        Rare: { rating: 1, colour: '#8267a5' },
        'Very Rare': { rating: 2, colour: '#a68bbf' },
        'Extremely Rare': { rating: 2, colour: '#a68bbf' },
      },
    },
    Lowest: {
      Insignificant: {
        'Almost Certain': { rating: 2, colour: '#a68bbf' },
        Likely: { rating: 3, colour: '#cfbedd' },
        Unlikely: { rating: 3, colour: '#cfbedd' },
        Rare: { rating: 4, colour: '#eae7f0' },
        'Very Rare': { rating: 4, colour: '#eae7f0' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Minor: {
        'Almost Certain': { rating: 2, colour: '#a68bbf' },
        Likely: { rating: 2, colour: '#a68bbf' },
        Unlikely: { rating: 3, colour: '#cfbedd' },
        Rare: { rating: 3, colour: '#cfbedd' },
        'Very Rare': { rating: 4, colour: '#eae7f0' },
        'Extremely Rare': { rating: 4, colour: '#eae7f0' },
      },
      Moderate: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 2, colour: '#a68bbf' },
        Rare: { rating: 2, colour: '#a68bbf' },
        'Very Rare': { rating: 3, colour: '#cfbedd' },
        'Extremely Rare': { rating: 3, colour: '#cfbedd' },
      },
      Major: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 1, colour: '#8267a5' },
        Rare: { rating: 1, colour: '#8267a5' },
        'Very Rare': { rating: 2, colour: '#a68bbf' },
        'Extremely Rare': { rating: 2, colour: '#a68bbf' },
      },
      Catastrophic: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 1, colour: '#8267a5' },
        Rare: { rating: 1, colour: '#8267a5' },
        'Very Rare': { rating: 1, colour: '#8267a5' },
        'Extremely Rare': { rating: 2, colour: '#a68bbf' },
      },
    },
  };

  static RISK_DETAILS: RiskDetail[] = [
    {
      riskRef: 'M-L 01',
      hazardType: 'Flood - river',
      analysisZone: 'Local Government Areas',
      infrastructure: 'Transport',
      feature: 'Structures',
      hazardStatement: 'There is the potential for a high intensity storm...',
      consequenceStatement:
        'This may impact human life; residential and other buildings...',
      likelihood: 'Unlikely',
      impactArea: ['Structure', 'Economy'],
      maxConsequence: 'Major',
      impactedAreas: [
        {
          impactArea: 'Structure',
          consequence: 'Minor',
          controlStrength: 'Medium',
          controlExpediency: 'Medium',
          confidenceLevel: 'Highest',
          natureOfImpactTo: 'This is a testing message',
          existingControls:
            'Tasmanian Planning Scheme\nBuilding Regulations 2016 - Part 5',
          controlEffectiveness: 'Medium',
          likelihoodAfterControl: 'Unlikely',
          risk: 'High',
          priority: '3',
        },
        {
          impactArea: 'Economy',
          consequence: 'Moderate',
          controlStrength: 'Medium',
          controlExpediency: 'Medium',
          confidenceLevel: 'Highest',
          natureOfImpactTo: 'This is a testing message',
          existingControls:
            'Tasmanian Planning Scheme\nBuilding Regulations 2016 - Part 5',
          controlEffectiveness: 'Medium',
          likelihoodAfterControl: 'Unlikely',
          risk: 'High',
          priority: '3',
        },
      ],
      treatmentOptions: 'Treatment',
      riskTreatmentStrategies: 'Change the consequence of the risk',
      riskTreatmentActions: 'Central Coast Council Flood Management Plan...',
      riskRanking: '',
      municipalities: ['Central Coast', 'Devonport City', 'Kentish'],
      region: 'North West',
      notes: 'Bass Highway bridges (road & rail)...',
      referenceMaterial:
        'LISTmap layers: CFEV Catchments and Sub-Catchments...',
      preventionMitigationManagementAgency: 'Council',
      preparednessManagementAgency: 'SES',
      responseManagementAgency: 'SES, TASPOL, Council',
    },
    {
      riskRef: 'M-L 02',
      hazardType: 'Flood - river',
      analysisZone: 'Local Government Areas',
      infrastructure: 'Transport',
      feature: 'Structures',
      hazardStatement: 'There is the potential for a high intensity storm...',
      consequenceStatement:
        'This may impact human life; residential and other buildings...',
      likelihood: 'Unlikely',
      impactArea: ['Environment'],
      maxConsequence: 'Major',
      impactedAreas: [
        {
          impactArea: 'Environment',
          consequence: 'Minor',
          controlStrength: 'Medium',
          controlExpediency: 'Medium',
          confidenceLevel: 'Highest',
          natureOfImpactTo: 'This is a testing message',
          existingControls:
            'Tasmanian Planning Scheme\nBuilding Regulations 2016 - Part 5',
          controlEffectiveness: 'Medium',
          likelihoodAfterControl: 'Unlikely',
          risk: 'High',
          priority: '3',
        },
      ],
      treatmentOptions: 'Treatment',
      riskTreatmentStrategies: 'Change the consequence of the risk',
      riskTreatmentActions: 'Central Coast Council Flood Management Plan...',
      riskRanking: '',
      municipalities: ['Central Coast', 'Devonport City', 'Kentish'],
      region: 'North West',
      notes: 'Bass Highway bridges (road & rail)...',
      referenceMaterial:
        'LISTmap layers: CFEV Catchments and Sub-Catchments...',
      preventionMitigationManagementAgency: 'Council',
      preparednessManagementAgency: 'SES',
      responseManagementAgency: 'SES, TASPOL, Council',
    },
    {
      riskRef: 'M-L 03',
      hazardType: 'Flood - river',
      analysisZone: 'Local Government Areas',
      infrastructure: 'Transport',
      feature: 'Structures',
      hazardStatement: 'There is the potential for a high intensity storm...',
      consequenceStatement:
        'This may impact human life; residential and other buildings...',
      likelihood: 'Unlikely',
      impactArea: ['Structure', 'Economy'],
      maxConsequence: 'Major',
      impactedAreas: [
        {
          impactArea: 'Structure',
          consequence: 'Catastrophic',
          controlStrength: 'Medium',
          controlExpediency: 'Medium',
          confidenceLevel: 'Highest',
          natureOfImpactTo: 'This is a testing message',
          existingControls:
            'Tasmanian Planning Scheme\nBuilding Regulations 2016 - Part 5',
          controlEffectiveness: 'Medium',
          likelihoodAfterControl: 'Unlikely',
          risk: 'High',
          priority: '3',
        },
        {
          impactArea: 'Economy',
          consequence: 'Major',
          controlStrength: 'Medium',
          controlExpediency: 'Medium',
          confidenceLevel: 'Highest',
          natureOfImpactTo: 'This is a testing message',
          existingControls:
            'Tasmanian Planning Scheme\nBuilding Regulations 2016 - Part 5',
          controlEffectiveness: 'Medium',
          likelihoodAfterControl: 'Unlikely',
          risk: 'High',
          priority: '3',
        },
      ],
      treatmentOptions: 'Treatment',
      riskTreatmentStrategies: 'Change the consequence of the risk',
      riskTreatmentActions: 'Central Coast Council Flood Management Plan...',
      riskRanking: '',
      municipalities: ['Central Coast', 'Devonport City', 'Kentish'],
      region: 'North West',
      notes: 'Bass Highway bridges (road & rail)...',
      referenceMaterial:
        'LISTmap layers: CFEV Catchments and Sub-Catchments...',
      preventionMitigationManagementAgency: 'Council',
      preparednessManagementAgency: 'SES',
      responseManagementAgency: 'SES, TASPOL, Council',
    },
    {
      riskRef: 'M-L 04',
      hazardType: 'Flood - river',
      analysisZone: 'Local Government Areas',
      infrastructure: 'Transport',
      feature: 'Structures',
      hazardStatement: 'There is the potential for a high intensity storm...',
      consequenceStatement:
        'This may impact human life; residential and other buildings...',
      likelihood: 'Unlikely',
      impactArea: ['Structure', 'Economy'],
      maxConsequence: 'Major',
      impactedAreas: [
        {
          impactArea: 'Structure',
          consequence: 'Moderate',
          controlStrength: 'Medium',
          controlExpediency: 'Medium',
          confidenceLevel: 'Highest',
          natureOfImpactTo: 'This is a testing message',
          existingControls:
            'Tasmanian Planning Scheme\nBuilding Regulations 2016 - Part 5',
          controlEffectiveness: 'Medium',
          likelihoodAfterControl: 'Unlikely',
          risk: 'High',
          priority: '3',
        },
        {
          impactArea: 'Economy',
          consequence: 'Moderate',
          controlStrength: 'Medium',
          controlExpediency: 'Medium',
          confidenceLevel: 'Highest',
          natureOfImpactTo: 'This is a testing message',
          existingControls:
            'Tasmanian Planning Scheme\nBuilding Regulations 2016 - Part 5',
          controlEffectiveness: 'Medium',
          likelihoodAfterControl: 'Unlikely',
          risk: 'High',
          priority: '3',
        },
      ],
      treatmentOptions: 'Treatment',
      riskTreatmentStrategies: 'Change the consequence of the risk',
      riskTreatmentActions: 'Central Coast Council Flood Management Plan...',
      riskRanking: '',
      municipalities: ['Central Coast', 'Devonport City', 'Kentish'],
      region: 'North West',
      notes: 'Bass Highway bridges (road & rail)...',
      referenceMaterial:
        'LISTmap layers: CFEV Catchments and Sub-Catchments...',
      preventionMitigationManagementAgency: 'Council',
      preparednessManagementAgency: 'SES',
      responseManagementAgency: 'SES, TASPOL, Council',
    },
  ];
}
