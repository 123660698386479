import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { Type } from 'ol/geom/Geometry';
import BaseLayer from 'ol/layer/Base';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import {
  CategoryInfo,
  LayerInfo,
} from '../../../catalogue-page/data-access/catalogue-config.model';
import {
  CategoryGroup,
  LayerConfig,
} from '../../../../data-access/models/layer-config';
import { Map as olMap } from 'ol';
import { LayerStorage } from '../layer-storage.model';
import { MapService } from '../../../../data-access/services/map.service';

export interface state {
  title: string;
  visible: 'visible' | 'hidden' | 'partial';
  opacity: number;
  kind?: Type;
  featureProperties?: {};
  layer: any;
  type: 'group' | 'vector' | 'image';
  children: WritableSignal<state>[];
  legendURL?: string;
  sourceType?: string;
  hasRemoveFn: boolean;
  hasRequiredZoom: boolean;
  canActivate: boolean;
  minZoom: number;
  removeFn?: (event) => {};
}

// TODO: rebuilding the entire tree every-time seems like a waste, maybe do some checking / filtering to only re-create the needed ones. THIS REALLY NEEDS TO BE DONE!!!

@Injectable()
export class LayersService {
  private readonly _KEY: string = 'layers';

  catalogue: CategoryInfo[];
  addedLayers: CategoryGroup[];
  public readonly mapService: MapService = inject(MapService);
  public mapInstance: olMap = this.mapService.map;
  test: WritableSignal<state>[] = [];
  layers;
  persistedLayers: LayerStorage;

  constructor() {
    this.catalogue = LayerConfig.LAYERS.map((g): CategoryInfo => {
      return {
        name: g.name,
        layers: g.categories[0].layers.map((l) => {
          return { name: l.name, added: l.visibleByDefault };
        }),
      };
    });

    this.persistedLayers = JSON.parse(localStorage.getItem(this._KEY));

    if (this.persistedLayers && Object.keys(this.persistedLayers).length > 0) {
      for (const [lGName, lGroup] of Object.entries(this.persistedLayers)) {
        const groupLayers = [];
        const cg = this.catalogue.find((c) => c.name === lGName);
        const group = LayerConfig.LAYERS.find((g) => g.name === lGName);

        for (const [lName, lState] of Object.entries(lGroup.layers)) {
          cg.layers.find((c) => c.name === lName).added = true;
          const layer = this._addLayer(
            group.categories[0].layers.find((l) => l.name === lName)
          );
          layer.setVisible(lState.visibility);
          layer.setOpacity(lState.opacity);
          groupLayers.push(layer);
        }
        this.mapInstance.addLayer(
          new LayerGroup({
            layers: groupLayers,
            properties: { title: lGName },
            visible: lGroup.visibility,
          })
        );
      }
    } else {
      this.persistedLayers = {};
      this.addedLayers = LayerConfig.LAYERS.map((cG) => ({
        ...cG,
        categories: cG.categories.map((cC) => ({
          ...cC,
          layers: cC.layers.filter((l) => l.visibleByDefault),
        })),
      })).filter((g) => g.categories.some((c) => c.layers.length > 0));

      this.addedLayers.forEach((cg: CategoryGroup) => {
        const groupLayers = [];
        let groupStore = {};
        cg.categories[0].layers.forEach((lc: LayerConfig) => {
          groupLayers.push(this._addLayer(lc));
          groupStore[lc.name] = this._basePersistLayer();
        });
        this.persistedLayers[cg.name] = {
          visibility: true,
          layers: groupStore,
        };
        this.mapInstance.addLayer(
          new LayerGroup({
            layers: groupLayers,
            properties: { title: cg.name },
          })
        );
      });
      localStorage.setItem(this._KEY, JSON.stringify(this.persistedLayers));
    }

    this._buildLayersTree();

    this.mapInstance.getLayers().on(['add', 'remove'], (event: any) => {
      this._buildLayersTree();

      if (event.element) this._attachAddListenerToLayer(event.element);
    });

    this.mapInstance.getLayers().forEach((layer) => {
      this._attachAddListenerToLayer.call(this, layer);
    });
  }

  removeLayerFromMap(group: state, layer: state) {
    const lG = this.catalogue.find((c) => c.name == group.layer.get('title'));
    const l = lG.layers.find((l) => l.name == layer.layer.get('title'));

    l.added = false;
    this.mapInstance.removeLayer(layer.layer);
    if (group.layer.getLayers().getArray().length === 1) {
      this.mapInstance.removeLayer(group.layer);
    }
    this._removeFromState(group.layer.get('title'), layer.layer.get('title'));
  }

  removeLayer(
    group: CategoryInfo,
    gI: number,
    layerInfo: LayerInfo,
    lI: number
  ) {
    this.catalogue[gI].layers[lI].added = false;

    this.mapInstance
      .getLayers()
      .getArray()
      .forEach((subLayers) => {
        if (subLayers.getProperties()['title'] === group.name) {
          subLayers.getLayersArray().forEach((layer) => {
            if (layer.get('title') === layerInfo.name) {
              this.mapInstance.removeLayer(layer);
              if (subLayers.getLayersArray().length === 1) {
                this.mapInstance.removeLayer(subLayers);
              }
            }
          });
        }
      });

    this._removeFromState(group.name, layerInfo.name);
  }

  addLayerToMap(group: CategoryInfo, gI: number, layer: LayerInfo, lI: number) {
    this.catalogue[gI].layers[lI].added = true;
    let layerGroup = undefined;

    const layerToAdd = LayerConfig.LAYERS.find(
      (g) => g.name === group.name
    ).categories[0].layers.find((l) => l.name === layer.name);

    this.mapInstance.getLayers().forEach((baseLayer: BaseLayer) => {
      if (
        baseLayer instanceof LayerGroup &&
        baseLayer.get('title') === group.name
      ) {
        layerGroup = baseLayer;
        return;
      }
    });

    if (layerGroup) {
      layerGroup.getLayers().push(this._addLayer(layerToAdd, true));
    } else {
      this.mapInstance.addLayer(
        new LayerGroup({
          layers: [this._addLayer(layerToAdd, true)],
          properties: { title: group.name },
        })
      );
    }
    this._addToState(group.name, layer.name);
  }

  _addToState(groupName: string, layerName: string) {
    if (groupName in this.persistedLayers) {
      this.persistedLayers[groupName].layers[layerName] =
        this._basePersistLayer();
    } else {
      this.persistedLayers[groupName] = {
        visibility: true,
        layers: {
          [layerName]: this._basePersistLayer(),
        },
      };
    }
    localStorage.setItem(this._KEY, JSON.stringify(this.persistedLayers));
  }

  _removeFromState(groupName: string, layerName: string) {
    for (const [lGName, lGroup] of Object.entries(this.persistedLayers)) {
      if (groupName === lGName) {
        for (const [lName] of Object.entries(lGroup.layers)) {
          if (lName === layerName) {
            delete this.persistedLayers[lGName].layers[lName];
            console.log(
              Object.keys(this.persistedLayers[lGName].layers).length
            );
            if (Object.keys(this.persistedLayers[lGName].layers).length === 0) {
              delete this.persistedLayers[lGName];
            }
          }
        }
      }
    }
    localStorage.setItem(this._KEY, JSON.stringify(this.persistedLayers));
  }

  _addLayer(lc: LayerConfig, addingLayer: boolean = false) {
    switch (lc.type) {
      case 'ArcGISRest':
        return this.mapService.getArcGISRestLayer(lc, addingLayer);
      case 'WFS':
        return this.mapService.getWFSLayer(lc, addingLayer);
      case 'WMTS':
        return this.mapService.getWMTSLayer(lc, addingLayer);
      default:
        return null;
    }
  }

  _basePersistLayer() {
    return {
      visibility: true,
      opacity: 1,
    };
  }

  _buildLayersTree() {
    this.test = [];

    this.layers = this.mapInstance
      .getLayers()
      .getArray()
      .filter(
        (layer) =>
          layer.get('title') != 'Background Maps' && layer.get('title') != null
      );

    this.layers.forEach((layer) => {
      if (layer instanceof LayerGroup) {
        let layerState = this._buildLayerGroupState(layer, null);
        this.test.push(layerState);
      } else {
        let layerState = this._buildLayerState(layer, null);
        this.test.push(layerState);
      }
    });
  }

  _buildLayerGroupState(layerGroup: LayerGroup, parentState) {
    let layersArray = layerGroup
      .getLayers()
      .getArray()
      .filter((layer) => layer.get('title') != null);

    let state = signal<state>({
      title: layerGroup.get('title'),
      opacity: layerGroup.getOpacity(),
      visible: layerGroup.getVisible() ? 'visible' : 'hidden',
      layer: layerGroup,
      type: 'group',
      children: [],
      hasRemoveFn: layerGroup.get('removeFn') != null,
      hasRequiredZoom: layerGroup.getMinZoom() != -Infinity,
      canActivate: true,
      removeFn:
        layerGroup.get('removeFn') == null ? null : layerGroup.get('removeFn'),
      minZoom: layerGroup.get('minZoom'),
    });

    layerGroup.on('change:opacity', () => {
      let opacity = layerGroup.getOpacity();
      state.set({ ...state(), opacity: opacity });
    });

    layerGroup.on('change:visible', () => {
      state.set({
        ...state(),
        visible: layerGroup.getVisible() ? 'visible' : 'hidden',
      });
      this.persistedLayers[layerGroup.get('title')].visibility =
        layerGroup.getVisible();
      localStorage.setItem(this._KEY, JSON.stringify(this.persistedLayers));
    });

    layersArray.forEach((layer) => {
      if (layer instanceof LayerGroup) {
        state().children.push(this._buildLayerGroupState(layer, state));
      } else {
        let childState = this._buildLayerState(layer, state);
        state().children.push(childState);
      }
    });

    return state;
  }

  _buildLayerState(layer: BaseLayer, parentState: WritableSignal<state>) {
    let state = signal<state>({
      title: layer.get('title'),
      opacity: layer.getOpacity(),
      visible: layer.getVisible() ? 'visible' : 'hidden',
      layer: layer,
      type: 'image',
      featureProperties: layer.getProperties(),
      children: [],
      legendURL: layer.get('legendURL'),
      sourceType: layer.get('sourceType'),
      hasRemoveFn: layer.get('removeFn') != null,
      hasRequiredZoom: layer.getMinZoom() != -Infinity,
      removeFn: layer.get('removeFn') == null ? null : layer.get('removeFn'),
      canActivate: true,
      minZoom: layer.getMinZoom(),
    });

    if (state().hasRequiredZoom) {
      this.mapService.map.on('moveend', () => {
        let atRequiredZoom = this._calculateIfAtRequiredZoom(
          this.mapService.map.getView().getZoom(),
          layer.getMinZoom()
        );

        state.set({ ...state(), canActivate: atRequiredZoom });
      });

      let atRequiredZoom = this._calculateIfAtRequiredZoom(
        this.mapService.map.getView().getZoom(),
        layer.getMinZoom()
      );

      state.set({ ...state(), canActivate: atRequiredZoom });
    }

    if (layer instanceof VectorLayer || layer instanceof VectorImageLayer) {
      state.set({ ...state(), type: 'vector' });

      let source = layer.getSource() as VectorSource;

      if (source.getFeatures().length > 0) {
        let feature = source.getFeatures()[0];
        let kind = feature.getGeometry().getType();
        let properties = feature.getProperties();
        delete properties['geometry'];

        state.set({ ...state(), kind: kind, featureProperties: properties });
      } else {
        source.on('addfeature', (event) => {
          let kind = event.feature.getGeometry().getType();
          let properties = event.feature.getProperties();

          delete properties['geometry'];

          state.set({ ...state(), kind: kind, featureProperties: properties });
        });
      }
    }

    layer.on('change:opacity', () => {
      let opacity = layer.getOpacity();
      state.set({ ...state(), opacity: opacity });

      this.persistedLayers[parentState().layer.get('title')].layers[
        layer.get('title')
      ].opacity = opacity;
      localStorage.setItem(this._KEY, JSON.stringify(this.persistedLayers));
    });

    layer.on('change:visible', () => {
      state.set({
        ...state(),
        visible: layer.getVisible() ? 'visible' : 'hidden',
      });
      this.persistedLayers[parentState().layer.get('title')].layers[
        layer.get('title')
      ].visibility = layer.getVisible();
      localStorage.setItem(this._KEY, JSON.stringify(this.persistedLayers));
    });

    return state;
  }

  _attachAddListenerToLayer(layer) {
    if (layer instanceof LayerGroup) {
      layer.getLayers().on(['add', 'remove'], (event: any) => {
        if (event.element) {
          this._attachAddListenerToLayer(event.element);
        }
        this._buildLayersTree();
      });
      layer.getLayers().forEach((subLayer) => {
        this._attachAddListenerToLayer.call(this, subLayer);
      });
    }
  }

  _calculateIfAtRequiredZoom(currentZoom: number, minZoom: number): boolean {
    return currentZoom >= minZoom;
  }
}
