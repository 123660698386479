<div class="title-bar">
  <div class="title">Register Risk ({{data.count}} Bridge Structures)</div>
  <div class="window-controls">
    <button (click)="cancel()" ><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content">
  <form [formGroup]="form">
    <div class="grid grid-flow-row-dense grid-cols-8 grid-rows-1 space-x-1 mx-2 mt-4 row-span-12">
      <p-floatLabel class="col-span-4">
        <textarea  variant="outlined" rows="2" class="txt-area" [cols]="5" pInputTextarea
                   formControlName="hazardStatement" [autoResize]="true" id="hs">
        </textarea>
        <label for="hs">Risk Statement part A (Hazard)</label>
      </p-floatLabel>

      <p-floatLabel class="col-span-4">
        <textarea  variant="outlined" rows="2" class="txt-area" [cols]="5" pInputTextarea
                   formControlName="consequenceStatement" [autoResize]="true" id="consequenceStatement">
        </textarea>
        <label for="consequenceStatement">Risk Statement part B (Consequence)</label>
      </p-floatLabel>
    </div>

    <div class="grid grid-flow-row-dense grid-cols-8 grid-rows-1 space-x-1 mx-2 mt-3">
      <p-floatLabel class="col-span-3">
        <p-dropdown formControlName="likelihood" [options]="RiskWizard.LIKELIHOOD_LEVEL" id="likelihood"
                    [style]="{ width: '100%', border: 'solid 1px black' }"/>
        <label for="likelihood">Event Likelihood (AEP)</label>
      </p-floatLabel>

      <p-floatLabel class="col-span-3">
        <p-multiSelect formControlName="impactArea" [options]="RiskWizard.IMPACT_AREA" id="impactArea"
                       (onChange)="impactAreaChange($event)" [style]="{ width: '100%', border: 'solid 1px black' }"/>
        <label for="impactArea">Impact Area</label>
      </p-floatLabel>

      <p-floatLabel class="col-span-2">
        <p-dropdown [(ngModel)]="maxConsequence" [disabled]="true" optionLabel="name" optionValue="value"
                    [options]="RiskWizard.CONSEQUENCE" id="maxConsequence" [ngModelOptions]="{standalone: true}"
                    [style]="{ width: '100%', border: 'solid 1px black', background: '#efefef'}"/>
        <label for="impactArea">Maximum Consequence</label>
      </p-floatLabel>
    </div>

    @if (multipleImpactedAreas.length >= 1) {
      <p-accordion [multiple]="true" styleClass="mt-2">
        @for (group of impactedAreas.controls; track $index;) {
          <p-accordionTab [header]="multipleImpactedAreas[$index]">
            <div [formGroup]="riskInfoService.getFormGroup(group)">
              <div [formGroupName]="riskInfoService.getGroupKey(group)">
                <div class="grid grid-flow-row-dense grid-cols-8 grid-rows-1 space-x-1 mt-2">
                  <p-floatLabel class="col-span-2">
                    <p-dropdown formControlName="consequence" optionLabel="name" optionValue="value"
                                [options]="RiskWizard.CONSEQUENCE" id="consequence"
                                [style]="{ width: '100%', border: 'solid 1px black' }"/>
                    <label for="consequence">Consequence</label>
                  </p-floatLabel>
                  <p-floatLabel class="col-span-2">
                    <p-dropdown formControlName="ctrlStrength"
                                [options]="RiskWizard.CONTROL_STRENGTH_EXPEDIENCY" id="ctrlStrength"
                                [style]="{ width: '100%', border: 'solid 1px black' }"/>
                    <label for="ctrlStrength">Controls Strength</label>
                  </p-floatLabel>
                  <p-floatLabel class="col-span-2">
                    <p-dropdown formControlName="ctrlExpediency" [options]="RiskWizard.CONTROL_STRENGTH_EXPEDIENCY"
                                id="ctrlExpediency" [style]="{ width: '100%', border: 'solid 1px black' }"/>
                    <label for="ctrlExpediency">Controls Expediency</label>
                  </p-floatLabel>
                  <p-floatLabel class="col-span-2">
                    <p-dropdown formControlName="confidenceLvl"  [options]="RiskWizard.CONFIDENCE"
                                id="confidenceLvl" [style]="{ width: '100%', border: 'solid 1px black' }"/>
                    <label for="confidenceLvl">Confidence Level</label>
                  </p-floatLabel>
                </div>
                <div class="grid grid-flow-row-dense grid-cols-12 grid-rows-1 space-x-1 mt-4">
                  <p-floatLabel class="col-span-3">
                    <textarea  variant="outlined" rows="2" class="txt-area" [cols]="5" pInputTextarea
                               formControlName="impactNature" [autoResize]="true" id="impactNature">
                    </textarea>
                    <label for="impactNature">Nature of Impact to {{multipleImpactedAreas[$index]}}</label>
                  </p-floatLabel>
                  <p-floatLabel class="col-span-3">
                    <textarea  variant="outlined" rows="2" class="txt-area" [cols]="5" pInputTextarea
                               formControlName="existingControls" [autoResize]="true" id="existingControls">
                    </textarea>
                    <label for="existingControls">Existing Controls</label>
                  </p-floatLabel>
                  <div class="flex flex-col items-center text-center align-middle col-start-8 col-span-1">
                    <label class="inline-block matrix-lbl p-float-label">Effectiveness</label>
                    <div class="matrix-result" [style.background-color]="riskInfoService.getCtrlEffectiveness(group).colour">
                      <p class="matrix-value">{{riskInfoService.getCtrlEffectiveness(group).value}}</p>
                    </div>
                  </div>
                  <div class="flex flex-col items-center text-center align-middle col-start-9 col-span-1">
                    <label class="inline-block matrix-lbl p-float-label">Likelihood</label>
                    <div  class="matrix-result" [style.background-color]="riskInfoService.getLikelihoodAfterCtrl(group).colour">
                      <p class="matrix-value">{{riskInfoService.getLikelihoodAfterCtrl(group).value}}</p>
                    </div>
                  </div>
                  <div class="flex flex-col items-center text-center align-middle col-start-10 col-span-1">
                    <label class="inline-block matrix-lbl p-float-label">Risk</label>
                    <div class="matrix-result" [style.background-color]="riskInfoService.getRiskLevel(group).colour">
                      <p class="matrix-value">{{riskInfoService.getRiskLevel(group).value}}</p>
                    </div>
                  </div>
                  <div class="flex flex-col items-center text-center align-middle col-start-11 col-span-1">
                    <label class="inline-block matrix-lbl p-float-label">Priority</label>
                    <div class="matrix-result" [style.background-color]="riskInfoService.getPriorityLevel(group).colour">
                      <p class="matrix-value">{{riskInfoService.getPriorityLevel(group).rating}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>
        }
      </p-accordion>
    }
  </form>
</div>
<div class="footer mt-3">
  <button class="bg-slate-400 hover:bg-slate-600 text-white py-2 px-4 border border-slate-400 rounded" (click)="cancel()">Cancel</button>
  <button class="bg-sky-600 hover:bg-sky-800 text-white py-2 px-4 border border-sky-600 rounded" (click)="submit()">
    <span class="fa fa-save mr-1"></span>Submit
  </button>
</div>
