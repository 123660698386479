import { Component, inject, Inject } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { RiskWizard } from '../../../risk-wizard-page/data-access/models/risk-wizard';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { RiskDetail } from '../../../risk-wizard-page/data-access/models/risk-detail.model';
import { InputTextModule } from 'primeng/inputtext';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';

@Component({
  selector: 'app-create-treatment',
  standalone: true,
  imports: [
    AccordionModule,
    DropdownModule,
    FloatLabelModule,
    FormsModule,
    InputTextareaModule,
    MultiSelectModule,
    ReactiveFormsModule,
    InputTextModule,
    AutoCompleteModule,
  ],
  templateUrl: './create-treatment.component.html',
  styleUrl: './create-treatment.component.css',
})
export class CreateTreatmentComponent {
  form: FormGroup;
  readonly risk: RiskDetail;
  protected readonly RiskWizard = RiskWizard;
  private readonly dialogRef: DialogRef<CreateTreatmentComponent> = inject(
    DialogRef<CreateTreatmentComponent>
  );
  readonly formBuilder: FormBuilder = inject(FormBuilder);
  filteredStrategies: string[] = [];

  constructor(@Inject(DIALOG_DATA) protected data: { risk: RiskDetail }) {
    this.dialogRef.addPanelClass('jiofjeoi');

    this.risk = data.risk;
    this.form = this.formBuilder.group({
      impactArea: ['', [Validators.required]],
      options: ['', [Validators.required]],
      strategies: ['', [Validators.required]],
      actions: ['', [Validators.required]],
      notes: ['', [Validators.required]],
      referenceMaterial: ['', [Validators.required]],
      prevention: ['', [Validators.required]],
      preparedness: ['', [Validators.required]],
      response: ['', [Validators.required]],
    });

    if (this.risk.impactArea.length === 1) {
      this.form.get('impactArea').setValue(this.risk.impactArea[0]);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {}

  filterStrategies($event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = $event.query;

    for (let i = 0; i < RiskWizard.TREATMENT_STRATEGY.length; i++) {
      let strategy = this.RiskWizard.TREATMENT_STRATEGY[i];
      if (strategy.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(strategy);
      }
    }

    console.log(query, filtered);
    this.filteredStrategies = filtered;
    /*this.treatmentStrategies.filter(
      (s) => s.toLowerCase().indexOf(query.toLowerCase()) === 0
    );*/
  }
}
