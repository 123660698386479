import { Component, EventEmitter, inject, Output } from '@angular/core';
import { CreateEvent } from '../create-event';
import { RiskWizardService } from '../../data-access/services/risk-wizard.service';
import { Feature } from 'ol';
import { PrimeTemplate } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { JsonPipe, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-create-boundary',
  standalone: true,
  imports: [PrimeTemplate, TableModule, JsonPipe, NgOptimizedImage],
  templateUrl: './create-boundary.component.html',
  styleUrl: './create-boundary.component.css',
})
export class CreateBoundaryComponent {
  readonly riskWizardService: RiskWizardService = inject(RiskWizardService);
  @Output() createEvent = new EventEmitter<CreateEvent<Feature<any>>>();

  cancel() {
    this.createEvent.emit(CreateEvent.cancel());
  }

  finished() {
    this.createEvent.emit(
      CreateEvent.create(this.riskWizardService.hazardBoundary)
    );
  }

  undo() {
    this.riskWizardService.interaction.removeLastPoint();
  }
}
