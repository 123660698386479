<sidebar [routeConfig]="routeConfig" routePrefix="/map">
  <div #map class="map"></div>
  @if (mapService.retrievingDataFromService) {
    <div class="loading-msg">
      <mat-spinner [diameter]="50"></mat-spinner>
      <div>Retrieving boundary from the Web Service ...</div>
    </div>
  }
  <app-layer-info-dialog #overlay></app-layer-info-dialog>
  <app-bottom-drawer></app-bottom-drawer>
</sidebar>
