import {
  Component,
  ElementRef,
  effect,
  inject,
  viewChild,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { MapService } from '../data-access/services/map.service';
import { MapChildRoutes } from '../routing/map.routes';
import { SidebarComponent } from '../ui/sidebar/sidebar.component';
import { LayerInfoDialogComponent } from '../features/layer-info-dialog/layer-info-dialog.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ModalService } from '../../../shared/services/modal/modal.service';
import { AppSettingsService } from '../data-access/services/app-settings.service';
import { filter, switchMap, tap } from 'rxjs';
import { LayersService } from '../features/layers-page/data-access/services/layers.service';
import { BottomDrawerComponent } from '../features/bottom-drawer/bottom-drawer.component';

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [
    SidebarComponent,
    LayerInfoDialogComponent,
    MatProgressSpinner,
    BottomDrawerComponent,
  ],
  templateUrl: './map.component.html',
  styleUrl: './map.component.css',
})
export class MapComponent implements OnInit, AfterViewInit {
  readonly mapService: MapService = inject(MapService);
  readonly modalService: ModalService = inject(ModalService);
  readonly appSettingsService: AppSettingsService = inject(AppSettingsService);
  readonly layerService: LayersService = inject(LayersService);

  public readonly routeConfig = MapChildRoutes;

  mapElement = viewChild.required<ElementRef<HTMLElement>>('map');
  overlayComponent = viewChild.required<LayerInfoDialogComponent>('overlay');

  elementLoadedEffect = effect(() => {
    this.mapService.init(
      this.mapElement().nativeElement,
      this.overlayComponent
    );
  });

  ngOnInit() {
    this.appSettingsService
      .getDevTermsAccepted()
      .pipe(
        filter((accepted) => !accepted),
        switchMap(() =>
          this.modalService.showDevMessage().pipe(
            tap((savePreference) => {
              this.appSettingsService.setDevTermsAccepted(savePreference);
            })
          )
        )
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.mapService.findCurrentZoomLevel();
    this.mapService.clickedOnMap();
  }
}
