import BaseLayer from "ol/layer/Base";

export class layersControl
{
  changeVisibility(arg0: BaseLayer, currentVisibleState: 'visible' | 'hidden' | 'partial', event) {
    event.preventDefault();
    event.stopPropagation();

    if(currentVisibleState == 'visible' || currentVisibleState == 'partial')
    {
      arg0.setVisible(false);
    }
    else
    {
      arg0.setVisible(true);
    }


    }

    changeOpacity(layer: BaseLayer, newOpacity,event)
    {
      event.preventDefault();
      event.stopPropagation();
      layer.setOpacity(newOpacity);
    }
    mouseDown(event)
    {
      event.stopPropagation();
    }

}
