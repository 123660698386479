import { Injectable } from '@angular/core';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Coordinate } from 'ol/coordinate';
import { Draw } from 'ol/interaction';
import { Feature } from 'ol';
import { LineString, MultiPolygon, Point } from 'ol/geom';
import { RasterInfo } from '../../../../data-access/models/raster-info.model';

@Injectable({
  providedIn: 'root',
})
export class RiskWizardService {
  hazardSource: VectorSource;
  hazardLayer: VectorLayer<any>;
  hazardBoundary: Feature<MultiPolygon> | Feature<Point> | Feature<LineString>;
  coordinates = [];
  interaction: Draw;
  interactionType: 'Info' | 'Draw' | 'Select' = 'Info';
  completePolygon: boolean = false;
  rasterInfo: RasterInfo[];
  bridgeName: string;

  clearExistingFeatureInfo() {
    this.bridgeName = '';
    this.rasterInfo = [];
  }
}
