<cdk-accordion-item class="dc-accordion-item" role="button"
  [ngClass]="{ collapsed: accordion().expanded, expanded: accordion().expanded }">
  <div cdkDragHandle class="dc-accordion-item-header" (click)="accordion().toggle()"
    [ngClass]="{ 'sticky-header': accordion().expanded }">
    <span>
      <button LayerVisibilityControl class="feature-layer-accordion-item-button"
        [layer]="layer().layer" [currentVisibleState]="layer().visible"
        [disabled]="!layer().canActivate" (click)="clickedOnEnableDisable(layer().layer, accordion())">
        @switch (layer().visible) {
          @case ('visible') {
            <i class="fa fa-eye"></i>
          }
          @case ('partial') {
            <i class="fa fa-low-vision"></i>
          }
          @case ('hidden') {
            <i class="fa fa-eye-slash"></i>
          } @default {
          <i class="fa fa-eye"></i>
          }
        }
      </button>
      {{ layer().title }}
    </span>

    <div class="flex gap--sm align-center">
      <button (click)="removeLayer()">
        <span><i class="fa fa-trash"></i></span>
      </button>
      <span>
        @if (accordion().expanded) {
        <i class="fa-solid fa-chevron-up"></i>
        } @else {
        <i class="fa-solid fa-chevron-down"></i>
        }
      </span>
    </div>
  </div>
  <div class="dc-accordion-item-body" role="region"
    [@slideInOut]="accordion().expanded ? 'in' : 'out'">
    <div class="flex flex-col justify-center align-start gap--xs">
      <label>Opacity:
        <input autocomplete="off" data-lpignore="true" #opacityInput type="range" min="0" max="1"
               step="0.01" layerOpacityControl [layer]="layer().layer" [value]="layer().opacity"/>
      </label>
      @if (layer().legendURL && layer().sourceType != 'ArcGISRest') {
        <img [src]="layer().legendURL" alt="" srcset="" class="img-fit"/>
      }
      @else if(layer().legendURL && layer().sourceType == 'ArcGISRest') {
        @for (legendItem of legend(); track $index) {
          <span class="flex align-center gap--md">
            <img [src]="legendItem.imageSrc" alt="" />{{ legendItem.label }}
          </span>
        }
      }
      @if (layer().minZoom && layer().minZoom > layerService.mapService.currentZoomLevel) {
        <span class="min-zoom-msg">To view this layer the zoom level needs to be {{layer().minZoom}} or greater</span>
      }
    </div>
  </div>
</cdk-accordion-item>
