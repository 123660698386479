import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  inject,
} from '@angular/core';
import {
  NameState,
  NameType,
  WizardFeature,
} from '../data-access/models/hazard-types.model';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RiskWizard } from '../data-access/models/risk-wizard';
import { MapService } from '../../../data-access/services/map.service';
import { ComponentFactory } from '../../../../../shared/services/ComponentFactory/component-factory.service';
import { SidebarService } from '../../../ui/sidebar/data-access/sidebar.service';
import { CreateBoundaryComponent } from './create-boundary/create-boundary.component';
import { switchMap, tap, map } from 'rxjs';
import { CreateEvent } from './create-event';
import { ModalService } from '../../../../../shared/services/modal/modal.service';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Draw } from 'ol/interaction';
import { MultiPolygon, Polygon } from 'ol/geom';
import { RiskWizardService } from '../data-access/services/risk-wizard.service';
import { Feature, Map as olMap } from 'ol';
import { RegisterRiskComponent } from './register-risk/register-risk.component';
import BaseLayer from 'ol/layer/Base';
import { Layer } from 'ol/layer';
import { Fill, Stroke, Style } from 'ol/style';
import { LayersService } from '../../layers-page/data-access/services/layers.service';
import {
  CategoryInfo,
  LayerInfo,
} from '../../catalogue-page/data-access/catalogue-config.model';
import { FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-risk-wizard',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FloatLabelModule,
    DropdownModule,
    RadioButtonModule,
    FileUploadModule,
    TableModule,
  ],
  templateUrl: './risk-wizard.component.html',
  styleUrl: './risk-wizard.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskWizardComponent {
  readonly mapService: MapService = inject(MapService);
  readonly layerService: LayersService = inject(LayersService);
  readonly riskWizardService: RiskWizardService = inject(RiskWizardService);
  readonly componentFactoryService: ComponentFactory = inject(ComponentFactory);
  readonly sidebarService: SidebarService = inject(SidebarService);
  readonly modalService: ModalService = inject(ModalService);

  readonly hazards: NameState[] = RiskWizard.ALL;
  readonly analysisZones: NameType[] = RiskWizard.ANALYSIS_ZONES;
  readonly infrastructures: string[] = RiskWizard.INFRASTRUCTURE;
  readonly features: WizardFeature[] = RiskWizard.FEATURE;
  readonly selectionTypes: NameType[] = RiskWizard.SELECTION_TYPE;
  readonly ADMIN_KEY: string = 'Administrative Boundaries';

  public form: FormGroup;

  private _map: olMap = this.mapService.getMap();
  private _drawnFeatures: Feature<any>;
  private _dataFromMapService: any[];
  private _admin_Group: CategoryInfo = this.layerService.catalogue.find(
    (c) => c.name === this.ADMIN_KEY
  );
  private _admin_GroupI: number = this.layerService.catalogue.indexOf(
    this._admin_Group
  );

  constructor() {
    this.mapService.analysisZoneLayer = undefined;
    this.form = new FormGroup({
      hazardType: new FormControl<NameState | null>(null),
      analysisZone: new FormControl(''),
      infrastructure: new FormControl(''),
      feature: new FormControl(''),
      selectionType: new FormControl({} as NameType),
    });

    this.form.get('analysisZone').valueChanges.subscribe((value: NameType) => {
      const layer: LayerInfo = this._admin_Group.layers.find(
        (l) => l.name === value.name
      );
      const lI: number = this._admin_Group.layers.indexOf(layer);
      if (!this.layerService.persistedLayers[this.ADMIN_KEY]) {
        this.layerService.addLayerToMap(
          this._admin_Group,
          this._admin_GroupI,
          layer,
          lI
        );
      } else if (
        !this.layerService.persistedLayers[this.ADMIN_KEY].layers[value.name]
      ) {
        this.layerService.addLayerToMap(
          this._admin_Group,
          this._admin_GroupI,
          layer,
          lI
        );
      } else {
        this._map.getLayers().forEach((subLayers: BaseLayer) => {
          if (subLayers.getProperties()['title'] != undefined) {
            subLayers.getLayersArray().forEach((layer: Layer<any>) => {
              /*if (
                this.mapService.analysisZoneLayer &&
                layer.get('title') ===
                  this.mapService.analysisZoneLayer.get('title')
              ) {
                layer.setVisible(false);
              } else*/ if (layer.get('title') === value.type) {
                layer.setVisible(true);
                this.mapService.analysisZoneLayer = layer;
                return;
              }
            });
          }
        });
      }
    });

    this.form.get('selectionType').valueChanges.subscribe((value: NameType) => {
      value.type === '';
    });
  }

  get isFileUpload(): boolean {
    return (
      (this.form.get('selectionType').value as NameType)?.type === 'Upload'
    );
  }

  get isPolygonSelection(): boolean {
    return (
      (this.form.get('selectionType').value as NameType)?.type === 'Polygon'
    );
  }

  drawSelection() {
    let component = this.componentFactoryService.appendComponentToBody(
      CreateBoundaryComponent
    );
    this.sidebarService.close();
    this._handleCreateEvent(component, component.instance.createEvent);
    this.riskWizardService.hazardSource = new VectorSource();
    this.riskWizardService.hazardLayer = new VectorLayer({
      source: this.riskWizardService.hazardSource,
    });

    this.mapService.interactionType.set('Draw');

    this._map.addLayer(this.riskWizardService.hazardLayer);

    this.riskWizardService.interaction = new Draw({
      type: this.form.get('selectionType').value?.type,
      source: this.riskWizardService.hazardSource,
    });

    this._map.addInteraction(this.riskWizardService.interaction);

    this.riskWizardService.interaction.on('drawstart', () => {
      this.riskWizardService.completePolygon = false;
    });

    this.riskWizardService.interaction.on('drawend', (event) => {
      this.riskWizardService.completePolygon = true;
      let eventGeom: Polygon = event.feature.getGeometry() as Polygon;
      let newCoordinates = eventGeom.getCoordinates() as any;

      this.riskWizardService.coordinates.push(newCoordinates);

      if (!this.riskWizardService.hazardBoundary) {
        this.riskWizardService.hazardBoundary = new Feature(
          new MultiPolygon(this.riskWizardService.coordinates)
        );

        this.riskWizardService.hazardSource.addFeature(
          this.riskWizardService.hazardBoundary
        );
      } else {
        this.riskWizardService.hazardBoundary
          .getGeometry()
          .setCoordinates(this.riskWizardService.coordinates);
      }

      this.riskWizardService.hazardSource.removeFeature(event.feature);
    });
  }

  protected readonly Object = Object;

  boundarySelection() {
    this.mapService.interactionType.set('Select');
    this.mapService.isPointSelect = false;
    let component = this.componentFactoryService.appendComponentToBody(
      CreateBoundaryComponent
    );
    this.sidebarService.close();
    this._handleCreateEvent(component, component.instance.createEvent);
    this.riskWizardService.hazardSource = new VectorSource();
    this.riskWizardService.hazardLayer = new VectorLayer({
      source: this.riskWizardService.hazardSource,
      style: new Style({
        fill: new Fill({
          color: 'rgba(236,131,145,0.5)',
        }),
        stroke: new Stroke({
          color: 'rgb(255,16,16)',
          width: 2,
        }),
      }),
    });

    this._map.addLayer(this.riskWizardService.hazardLayer);
  }

  pointSelection() {
    this.mapService.interactionType.set('Select');
    this.mapService.isPointSelect = true;
    let component = this.componentFactoryService.appendComponentToBody(
      CreateBoundaryComponent
    );
    this.sidebarService.close();
    this._handleCreateEvent(component, component.instance.createEvent);
    this.riskWizardService.hazardSource = new VectorSource();

    this.riskWizardService.hazardLayer = new VectorLayer({
      source: this.riskWizardService.hazardSource,
      style: new Style({
        stroke: new Stroke({
          color: 'rgb(255,16,16)',
          width: 2,
        }),
      }),
    });

    this._map.addLayer(this.riskWizardService.hazardLayer);
  }
  _handleCreateEvent(
    component: ComponentRef<CreateBoundaryComponent>,
    event: EventEmitter<CreateEvent<Feature<any>>>
  ) {
    event
      .pipe(
        switchMap((event) =>
          this.modalService
            .showConfirmation(
              event.create
                ? 'Are you sure you want to create this boundary?'
                : 'Are you sure you want to cancel?'
            )
            .pipe(map((confirmed) => ({ confirmed, event })))
        ),
        tap(({ confirmed, event }) => {
          // if no and its not create
          if (!confirmed && !event.create) {
            return;
          } else if (confirmed && !event.create) {
            this._removeInteractionAndClear(component);
            this.sidebarService.open();
            return;
          } else if (confirmed && event.create) {
            this._removeInteractionAndClear(component);
            this._processBoundaryDraw(event.model);
          }
        })
      )
      .subscribe();
  }

  _removeInteractionAndClear(component: ComponentRef<CreateBoundaryComponent>) {
    this.mapService.interactionType.set('Info');
    component.destroy();
    this._map.removeInteraction(this.riskWizardService.interaction);
    this._map.removeLayer(this.riskWizardService.hazardLayer);
    this.riskWizardService.hazardSource.clear();
  }

  async _processBoundaryDraw(feature: Feature<any>) {
    let analysisLayer = this.form.get('analysisZone').value?.type;
    let selectedLayer = this.form.get('feature').value?.layer;

    this._drawnFeatures = feature;

    /*let params: EsriFeatureInfo = {
      geometry: `{"rings":${JSON.stringify(
        feature.getGeometry().getCoordinates()[0]
      )}}`,
      geometryType: 'esriGeometryPolygon',
      layerId: selectedLayer,
    };*/

    let count: number = 0;
    try {
      const res = await this.mapService.getBridgeDataFromWFSForAnArea(
        feature
          .getGeometry()
          .getCoordinates()[0][0]
          .flat()
          .toString()
          .replaceAll(',', ' ')
      );
      const data = await res.json();
      console.log(data, data.numberReturned);
      count = data.numberReturned;
    } catch (error) {
      console.error(error);
    } finally {
      //TODO
      let component = this.modalService.showComponent<RegisterRiskComponent>(
        RegisterRiskComponent,
        { count }
      );
      this.sidebarService.close();
      component.createEvent.subscribe((event) => {
        if (event.create) {
          console.log(event.model);
        }
      });
    }

    /*
        this.mapService
          .getDataFromArcGISMapServer(
            'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/Infrastructure/MapServer/identify',
            params
          )
          .then((res) => res.json())
          .then((data) => {
            this._dataFromMapService = data.results;
            console.log(data.results);
            let component = this.modalService.showComponent<RegisterRiskComponent>(
              RegisterRiskComponent,
              {}
            );
            this.sidebarService.close();
            component.createEvent.subscribe((event) => {
              if (event.create) {
                console.log(event.model);
              }
            });
          });*/
    // const props: Object = layer.getProperties();
    // const tileGrid = layer.getSource().getTileGrid();
    // this.mapService.getTileDataFromWMTSService();
  }

  temp() {
    let component = this.modalService.showComponent<RegisterRiskComponent>(
      RegisterRiskComponent,
      {}
    );
    this.sidebarService.close();

    component.createEvent.subscribe((event) => {
      if (event.create) {
        console.log(event.model);
      }
    });
  }

  upload($event: FileUploadEvent) {}
}
