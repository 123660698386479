interface AttributeKeyDictionary {
  [key: string]: AttributeConfig;
}

interface AttributeConfig {
  displayName: string;
  visible: boolean;
}

export class AttrDictionary {
  public static ATTR_DICT: AttributeKeyDictionary = {
    gid: { displayName: 'GID', visible: true },
    name: { displayName: 'Name', visible: true },
    name2: { displayName: 'Alternative Name', visible: true },
    plan_ref: { displayName: 'Plan Ref', visible: true },
    lga_code: { displayName: 'Local Government Area Code', visible: true },
    lga: { displayName: 'Local Government Area', visible: true },
    file: { displayName: 'File', visible: true },
    source_att: { displayName: 'Source Att', visible: true },
    shape_length: { displayName: 'Shape Length', visible: true },
    shape_area: { displayName: 'Shape Area', visible: true },
    shape: { displayName: 'Shape', visible: true },
    objectid: { displayName: 'Object ID', visible: true },
    fma_name: { displayName: 'Flood Management Area', visible: true },
    lga_name: { displayName: 'Local Government Area Name', visible: true },
    sub_name: { displayName: 'Suburb', visible: true },
    col3: { displayName: 'Suburb', visible: true },
    hsa_vers: { displayName: 'Human Settlement Area Version', visible: true },
    build_id: { displayName: 'Build Id', visible: true },
    build_ty: { displayName: 'Build Type', visible: true },
    height: { displayName: 'Height', visible: true },
    ufi: { displayName: 'Unique Feature Identifier', visible: true },
    created_on: { displayName: 'Created On', visible: true },
    feat_rel: { displayName: 'Feat Rel', visible: true },
    roof_const: { displayName: 'Roof Const', visible: true },
    wall_const: { displayName: 'Wall Const', visible: true },
    pid: { displayName: 'Pid', visible: true },
    pid_area: { displayName: 'Pid Area', visible: true },
    volume: { displayName: 'Volume', visible: true },
    folio: { displayName: 'Folio', visible: true },
    lpi: { displayName: 'Local Property Identifier', visible: true },
    lse_lic_na: { displayName: 'Lease License Name', visible: true },
    own_name: { displayName: 'Owner Name', visible: true },
    address: { displayName: 'Address', visible: true },
    own_add1: { displayName: 'Owner Address 1', visible: true },
    own_add2: { displayName: 'Owner Address 2', visible: true },
    own_add3: { displayName: 'Owner Address 3', visible: true },
    address0: { displayName: 'Address', visible: true },
    prop_add1: { displayName: 'Property Address 1', visible: true },
    prop_add2: { displayName: 'Property Address 2', visible: true },
    prop_add3: { displayName: 'Property Address 3', visible: true },
    prop_no_f: { displayName: 'Property No F', visible: true },
    prop_no_fs: { displayName: 'Property No Fs', visible: true },
    prop_no_t: { displayName: 'Property No T', visible: true },
    prop_no_ts: { displayName: 'Property No Ts', visible: true },
    street: { displayName: 'Street', visible: true },
    st_type: { displayName: 'Street Type', visible: true },
    suburb: { displayName: 'Suburb', visible: true },
    postcode: { displayName: 'Postcode', visible: true },
    prop_floor: { displayName: 'Property Floor', visible: true },
    prop_name: { displayName: 'Property Name', visible: true },
    municipali: { displayName: 'Municipali', visible: true },
    tenure_ty: { displayName: 'Tenure Type', visible: true },
    nomenclatu: { displayName: 'Nomenclatu', visible: true },
    cad_type1: { displayName: 'Cadastral Type1', visible: true },
    cad_type2: { displayName: 'Cadastral Type2', visible: true },
    meas_area: { displayName: 'Meas Area', visible: true },
    comp_area: { displayName: 'Comp Area', visible: true },
    con_year: { displayName: 'Constructed Year', visible: true },
    prop_value: { displayName: 'Property Value', visible: true },
    cap_value: { displayName: 'Cap Value', visible: true },
    aav: { displayName: 'Aav', visible: true },
    improv: { displayName: 'improvement', visible: true },
    prop_addre: { displayName: 'Property Address', visible: true },
    prop_add00: { displayName: 'Property Address', visible: true },
    prop_add01: { displayName: 'Property Address 1', visible: true },
    owner_addr: { displayName: 'Owner Address', visible: true },
    owner_ad00: { displayName: 'Owner Address', visible: true },
    owner_ad01: { displayName: 'Owner Address 1', visible: true },
    owner_ad02: { displayName: 'Owner Address 2', visible: true },
    land_use_c: { displayName: 'Land Use C', visible: true },
    improvemen: { displayName: 'improvement', visible: true },
    constructi: { displayName: 'Construction Year', visible: true },
    land_area: { displayName: 'Land Area', visible: true },
    building_s: { displayName: 'Building S', visible: true },
    bedroom_co: { displayName: 'Bedroom Count', visible: true },
    roof_const_1: { displayName: 'Roof Construction', visible: true },
    wall_const_1: { displayName: 'Wall Construction', visible: true },
    last_settl: { displayName: 'Last Settl', visible: true },
    build_id_int: { displayName: 'Build Id Int', visible: true },
    objectid_1: { displayName: 'Object ID 1', visible: true },
    unq_name: { displayName: 'Unique Name', visible: true },
    min_elev: { displayName: 'Min Elevation', visible: true },
    max_elev: { displayName: 'Max Elevation', visible: true },
    mean_elev: { displayName: 'Mean Elevation', visible: true },
    floor_height_synthetic: {
      displayName: 'Floor Height Synthetic',
      visible: true,
    },
    floor_height_measured: {
      displayName: 'Floor Height Measured',
      visible: true,
    },
    floor_height_riskmodel: {
      displayName: 'Floor Height Riskmodel',
      visible: true,
    },
    pct90: { displayName: 'Pct90', visible: true },
    bfrag_clas: { displayName: 'Bfrag Clas', visible: true },
    hh_1pc: { displayName: 'Hh 1pc', visible: true },
    hh_1pc_cc: { displayName: 'Hh 1pc Cc', visible: true },
    hh_halfpc: { displayName: 'Hh Halfpc', visible: true },
    custod_id: { displayName: 'Custod Id', visible: true },
    title: { displayName: 'Title', visible: true },
    site_class: { displayName: 'Site Class', visible: true },
    id: { displayName: 'Id', visible: true },
    descr: { displayName: 'Description', visible: true },
    pipe: { displayName: 'Pipeline Type', visible: true },
    total_buildings: { displayName: 'Total Buildings', visible: true },
    total_residential_buildings: {
      displayName: 'Total Residential Buildings',
      visible: true,
    },
    percent_inundated_res: {
      displayName: 'Percent Inundated Res',
      visible: true,
    },
    total_population: { displayName: 'Total Population', visible: true },
    population_impacted: { displayName: 'Population Impacted', visible: true },
    perc_pop_impacted: { displayName: 'Perc Pop Impacted', visible: true },
    total_crit_inf: { displayName: 'Total Crit Inf', visible: true },
    percent_inun_crit_inf: {
      displayName: 'Percent Inun Crit Inf',
      visible: true,
    },
    total_soc_setting: { displayName: 'Total Soc Setting', visible: true },
    percent_inun_soc_set: {
      displayName: 'Percent Inun Soc Set',
      visible: true,
    },
    total_core_func: { displayName: 'Total Core Func', visible: true },
    percent_inun_core_func: {
      displayName: 'Percent Inun Core Func',
      visible: true,
    },
    total_commer_ind: { displayName: 'Total Commer Ind', visible: true },
    percent_inun_comm_ind: {
      displayName: 'Percent Inun Comm Ind',
      visible: true,
    },
    mean_conseq_all: { displayName: 'Mean Conseq All', visible: true },
    mean_conseq_residential: {
      displayName: 'Mean Conseq Residential',
      visible: true,
    },
    mean_conseq_core_functions: {
      displayName: 'Mean Conseq Core Functions',
      visible: true,
    },
    mean_conseq_roads: { displayName: 'Mean Conseq Roads', visible: true },
    mean_conseq_social_set: {
      displayName: 'Mean Conseq Social Set',
      visible: true,
    },
    total_area: { displayName: 'Total Area', visible: true },
    total_area_flooded: { displayName: 'Total Area Flooded', visible: true },
    percent_area_flooded: {
      displayName: 'Percent Area Flooded',
      visible: true,
    },
    percent_roads_flooded: {
      displayName: 'Percent Roads Flooded',
      visible: true,
    },
    cap_value_impacted: { displayName: 'Cap Value Impacted', visible: true },
    percent_cap_value_impacted: {
      displayName: 'Percent Cap Value Impacted',
      visible: true,
    },
    consequence_people_absolute: {
      displayName: 'Consequence People Absolute',
      visible: true,
    },
    consequence_economy_absolute: {
      displayName: 'Consequence Economy Absolute',
      visible: true,
    },
    consequence_corefunctions_absolute: {
      displayName: 'Consequence Corefunctions Absolute',
      visible: true,
    },
    consequence_socialsetting_absolute: {
      displayName: 'Consequence Socialsetting Absolute',
      visible: true,
    },
    consequence_aggregated_absolute: {
      displayName: 'Consequence Aggregated Absolute',
      visible: true,
    },
    risk_assessment: { displayName: 'Risk Assessment', visible: true },
    gray_index: { displayName: 'Gray Index', visible: true },
    cid: { displayName: 'Cadastral Identifier', visible: true },
    pot_pid: { displayName: 'Potential Parcel Identifier', visible: true },
    feat_name: { displayName: 'Feature Name', visible: true },
    strata_lev: { displayName: 'Strata Level', visible: true },
    prop_add: { displayName: 'Property Address', visible: true },
    comfac_id: { displayName: 'Community Facility Id', visible: true },
    com_type1: { displayName: 'Facility Type', visible: true },
    com_type2: { displayName: 'Sport/Recreation Type', visible: true },
    nom_reg_no: { displayName: 'Nom Reg No', visible: true },
    site_name: { displayName: 'Site Name', visible: true },
    site_precision: { displayName: 'Site Precision', visible: true },
    max_antenna_height: { displayName: 'Max Antenna Height', visible: true },
    assignments_count: { displayName: 'Assignments Count', visible: true },
    client_names: { displayName: 'Client Names', visible: true },
    site_url: { displayName: 'Site Url', visible: true },
    locationid: { displayName: 'Location ID', visible: true },
    subtypecd: { displayName: 'Type ID', visible: true },
    d_subtypec: { displayName: 'Type', visible: true },
    shape_leng: { displayName: 'Shape Length', visible: true },
    jurisdiction_control: { displayName: 'Jurisdiciton', visible: true },
    full_street_name: { displayName: 'Street Name', visible: true },
    hierarchy: { displayName: 'Hierarchy', visible: true },
    one_way: { displayName: 'Direction', visible: true },
    status: { displayName: 'Status', visible: true },
  };
}
